import React from "react"
import FotoCulqui from "@components/pageCasosdeExito/image/imgPhotoEmpresa/costagas/FotoCostagas.png"
import FotoCostagas2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/costagas/FotoCostagas2.png"

//logo de empresas clientes
import Costagas from "@components/pageCasosdeExito/image/logoscustomer/Costagas.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import livechat from "@components/pageCasosdeExito/image/iconcards/livechat.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/costagas/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/costagas/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/costagas/img3.svg"

const text1 = (
  <span className="">
    Costagas es una empresa peruana con más de 40 años de trayectoria en el
    mercado dedicada a brindar servicios y productos de la industria energética,
    como comercialización de gas y abastecimiento GLP, para consumo masivo y
    corportativo.
    <br /> <br />
    Con la ayuda de Beex Costagas ha sido
    capaz de reducir el tiempo de espera de sus usuarios, disminuyéndolo
    únicamente hasta 1 minuto, lo que ha logrado mejorar sus ventas digitales en
    1.3x.{" "}
  </span>
)

const text2 = (
  <span>
    Costagas, es una de las empresas líderes en comercialización directa y por
    canales de distribución de balones de gas y abastecimiento de GLP a granel a
    nivel nacional. Actualmente cuenta con avanzadas plantas envasadoras en más
    de 10 ciudades del Perú, lo que la hace una de las empresas del sector con
    mayor alcance.
    <br /> <br />
    Además, en suministro y comercialización de gas licuado de petróleo para
    corporativos a múltiples industrias, como comercios en general, proyectos
    inmobiliarios, avícolas, y más.
    <br /> <br />
    Natalia Pachas, jefa de Marketing de Costagas, ha sido la responsable de la
    puesta en marcha de Beex en su operación digital. Con su equipo y con la
    ayuda de nuestro Customer Success, lograron acceder rápidamente al API de
    WhatsApp Business e integrar sus canales digitales en la plataforma, como
    Messenger e Instagram.
    <br /> <br />
    El objetivo era claro, lograr mejorar la experiencia digital de los
    usuarios. Al ser una marca reconocida, la cantidad de interacciones ya sea
    por consultas o compras, era tan numerosa que era imposible manejarlo con
    aplicaciones tradicionales.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
   “Desde el inicio y con el acompañamiento adecuado de Beex, nuestro equipo logró sacar provecho a las diferentes funcionalidades de la plataforma. Esto nos ayudó a mejorar los tiempos de espera, respuesta e índices de ventas.”
  </p>
)

const text4 = (
  <span>
    Ahí es donde Beex, logró unificar, integrar y automatizar todas las
    conversaciones de sus usuarios y así lograr índices de mejoras
    significativos para su operación, como mejora en el tiempo de espera,
    aumento de ventas y reducción del abandono.
  </span>
)

const text5 = (
  <span>
    Gracias al acompañamiento de Customer Success de Beex, Costagas logró
    acceder al API oficial de WhatsApp Business en menos de una semana.
    <br /> <br />
    Con el canal activo, se ha logrado unificar los diferentes canales de ventas
    inhouse de la organización bajo un único número, lo que ha logrado mejorar
    la experiencia de cara a sus clientes, ya que no tienen múltiples números de
    contacto.
    <br /> <br />
    ¿El resultado? Mejorar el tiempo de espera con sus usuarios de 6 minutos
    aproximados hasta menos de 1 minuto. Sin duda, contar con respuestas
    automáticas y enrutamiento inteligente de chats por este canal logró uno de
    los objetivos principales.
  </span>
)

const text6 = (
  <span>
    “La agilidad y flexibilidad que tenemos con WhatsApp es lo que necesitábamos
    debido a la gran cantidad de conversaciones entrantes que teníamos. Hemos
    logrado reducir el tiempo de espera hasta 1 minuto, lo que ha elevado la
    satisfacción de nuestros clientes.”
  </span>
)

const text7 = (
  <span>
    Además de WhatsApp, Costagas integró los canales de Messenger e Instagram,
    logrando comunicarse con sus usuarios mediante un único hilo de
    conversación, lo que les ha dado mayor flexibilidad al eliminar la necesidad
    de contar con diferentes plataformas abiertas para cada canal de atención.
    <br /> <br />
    Desde el inicio y con el acompañamiento adecuado, el equipo de Costagas
    logró sacar provecho a las diferentes funcionalidades de Beex, lo que
    logró mejorar sus tiempos de espera, respuesta e índices de ventas.
    <br /> <br />
    Respecto a esto último, el integrar y automatizar las conversaciones por
    estos canales digitales, les resultó en mejorar sus ventas en 1.3x, ya que
    no descuidaban ninguna interacción y resolvían los casos de mejor
    prospección en menos tiempo.
  </span>
)
const text8 = (
  <span>
    “Con Beex, hemos logrado una mejor supervisión y trazabilidad de las
    interacciones de nuestros clientes por los canales digitales, lo que nos ha
    permitido estandarizar nuestros protocolos de atención, llegando a tener
    1.3x más ventas de lo usual.”
  </span>
)
const text9 = (
  <span>
    También, Natalia cuenta que antes de Beex necesitaban tener dos áreas
    encargadas para las respuestas a los clientes, cada área incluso era un
    canal, pero hoy todo se centraliza en su Contact Center, lo que les permite
    una gestión más ágil.
    <br />
    <br />
    WhatsApp es el segundo canal más importante en su operación de Contact
    Center y nuestra plataforma les ha permitido categorizar las interacciones por este
    canal, lo que les permite conocer con mayor detalle el tipo de cliente que
    los contacta por este canal.
    <br />
    <br />
    Todos estos procesos les han permitido obtener mejoras en sus índices más
    importantes, como sus tiempos de respuesta y nivel de ventas, pero también
    ha hecho que el índice de abandono disminuya hasta en un 40% con respecto a
    sus procesos anteriores.
  </span>
)
const text10 = (
  <span>
    “Poder categorizar las interacciones que tenemos por WhatsApp y los demás
    canales, además de sumarles campos personalizados acorde a nuestro negocio,
    ha hecho que podamos conocer los tipos de clientes a detalle, por cada canal
    de contacto.”
  </span>
)

const text11 = (
  <span>
    Como es costumbre, Beex ha brindado a Costagas un óptimo SLA de soporte
    que hasta ahora ha cumplido siempre que se ha necesitado. Además, agradece
    que la implementación haya sido ágil y rápida, y que la plataforma sea súper
    user-friendly.
    <br />
    <br />
    Todo esto les va a permitir afinar sus estrategias de comunicación y
    experiencia de usuario para poder alcanzar sus objetivos comerciales de los
    próximos meses.
    <br />
    <br />
    En síntesis, se ha logrado mejorar los procesos omnicanales de Costagas y es
    un caso importante, ya que hablamos de una marca representativa a nivel
    nacional que cuenta con un nivel de interacciones importante para su
    operación de ventas, ayudando en la automatización de sus procesos que han
    llevado a mejorar estos importantes índices.
    <br />
    <br />
    Estos son algunos procesos con los que Beex ayuda
    a Costagas en su operación de atención al cliente y ventas:
  </span>
)

const data = {
  intro: {
    image: Costagas,
    link: "https://www.youtube.com/watch?v=5mY0u7506k8",
    classlogo: "logo__clientes--casa",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoCulqui,
    text4: text4,
    text5: text5,
    title5: "WhatsApp Business API",
    text6: text6,
    title7: "Contact Center omnicanal",
    text7: text7,
    text8: text8,
    text9: text9,
    title10: "Acompañamiento y respaldo que se siente",
    text10: text10,
    title11: "Experiencia con el producto",
    text11: text11,
    fototwo: FotoCostagas2,
    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
    
  },

  cards: [
    {
      title: "Whatsapp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para sus operaciones de atención al cliente y ventas. Beex logra que sus clientes conversen con Costagas mediante la App más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots por los canales de más interacción con sus clientes, como WhatsApp, Facebook Messenger e Instagram. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Omnicanalidad",
      img: livechat,
      info:
        "Sumado a WhatsApp, Costagas ha integrado Facebook Messenger e Instagram a Beex, logrando tener conversaciones por estos canales digitales a través de un solo hilo de conversación omnicanal en una sola ventana de conversación.",
    },
  ],
}

export default data
